import React, { useState, useEffect } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Checkbox from "../../../components/Checkbox";
import Loader from "../../../components/Loader";
import Row from "./Row";

// data
import { customers } from "../../../mocks/customers";

const Table = ({ className, activeTable, setActiveTable, orders, Loading, selectedOrder}) => {
  const [chooseAll, setСhooseAll] = useState(false);
  const [activeId, setActiveId] = useState(orders.id);
  const [allOrders, setallOrders] = useState(orders);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };
  useEffect(() => {
    
  }, [])


  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row, { [styles.active]: activeTable })}>
          <div className={styles.col}>
            {/* <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={() => setСhooseAll(!chooseAll)}
            /> */}
          </div>
          <div className={styles.col}>Dish Name</div>
          <div className={styles.col}>Phone</div>
          <div className={styles.col}>Price</div>
          <div className={styles.col}>Quantity</div>
          <div className={styles.col}>Comments</div>
          <div className={styles.col}>Status</div>
        </div>
        {orders.map((x, index) => (
          <Row
            item={x}
            key={index}
            activeTable={activeTable}
            setActiveTable={setActiveTable}
            activeId={activeId}
            setActiveId={setActiveId}
            selectedOrder={selectedOrder}
            value={selectedFilters.includes(x.id)}
            onChange={() => handleChange(x.id)}
          />
        ))}
      </div>
      {Loading ?
      <div className={styles.foot}>
        <button className={cn("button-stroke button-small", styles.button)}>
          <Loader className={styles.loader} />
          <span>Load more</span>
        </button>
      </div> : null}
    </div>
  );
};

export default Table;
