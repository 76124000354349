import React, { useState } from "react";
import cn from "classnames";
import styles from "./ImagesAndCTA.module.sass";
import sstyles from "../../Settings/ProfileInformation/ProfileInformation.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";
import Dropdown from "../../../components/Dropdown";
import user_logo from "../../../images/placeholder-restaurant.png"
import Icon from "../../../components/Icon";

const optionsPurchase = ["Purchase now", "Purchase tomorrow", "Buy later"];

const ImagesAndCTA = ({ item, className, uuserImg, iimagechanged, filee }) => {
  const [purchase, setPurchase] = useState(optionsPurchase[0]);
  const [userImg, setuserImg] = useState(item.dishimageURL);
  const [file, setFile] = useState("");
  return (
    <Card
      className={cn(styles.card, className)}
      title="Dish Image"
      classTitle="title-blue"
    >
      {/* <div className={sstyles.avatar} style={{width: 100, height: 100}}>
              <img src={item.resturantimageURL} alt="dish" />
            </div> */}
      {/* <div className={styles.images}>
      
        <File
          className={styles.field}
          title="Click or drop image"
          label="Product image"
         // tooltip="Maximum 100 characters. No HTML or emoji allowed"
        />
        <Dropdown
          className={styles.field}
          label="Dropdown"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          value={purchase}
          setValue={setPurchase}
          options={optionsPurchase}
        />
      </div> */}

      <div className={sstyles.profile}>
        <div className={sstyles.avatar}>
          <img src={(userImg != null) ? userImg : user_logo} alt="Avatar" />
          {/* <button className={styles.remove}>
            <Icon name="close" />
          </button> */}
        </div>
        <div className={sstyles.file}>
          <input type="file" onChange={event => {
            if (event.target.files && event.target.files[0]) {
              let imgurl = URL.createObjectURL(event.target.files[0])
              filee(event.target.files[0])
              uuserImg(imgurl)
              iimagechanged(true)
              setuserImg(imgurl)
            }
          }}
            accept="image/*" />
          <button className={cn("button", sstyles.button)} type="button">
            <Icon name="add" size="24" />
            <span>Upload new picture</span>
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ImagesAndCTA;
