import firebase from "firebase/compat/app"
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAk1dOSK8ppLoyMLNn67TOVlQJvgrzvBYQ",
  authDomain: "morrow-uae.firebaseapp.com",
  databaseURL: "https://morrow-uae-default-rtdb.firebaseio.com",
  projectId: "morrow-uae",
  storageBucket: "morrow-uae.appspot.com",
  messagingSenderId: "426801623740",
  appId: "1:426801623740:web:bf2f435caac60cce3e96b9",
  measurementId: "G-RLWV92PLSQ"
  };
  const firebaseApp= firebase.initializeApp(firebaseConfig)
  const db = firebaseApp.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();
  export { auth, db, firebase,storage };