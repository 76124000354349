import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Price.module.sass";
import sstyles from "../../../components/Schedule/Schedule.module.sass";

import Card from "../../../components/Card";
import Tooltip from "../../../components/Tooltip";
import TextInput from "../../../components/TextInput";
import Switch from "../../../components/Switch";
import Item from "../../../components/Schedule/Item";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import { format, parseISO } from "date-fns";
import DatePicker from "react-datepicker";

const Price = ({ item, className, ssalePrice, aactualPrice, qquantity, ddefaultStock, aaddBag, ccashonPickup, sstartTime, eendTime, ppickupstartTime , ppickupendTime, sselectedDay}) => {
  const [resolution, setResolution] = useState(true);
  const [salePrice, setsalePrice] = useState(item.SalePrice);
  const [actualPrice, setactualPrice] = useState(item.actualPrice);
  const [quantity, setquantity] = useState(item.Quantity);
  const [addBag, setaddBag] = useState(item.addBag);
  const [cashonPickup, setcashonPickup] = useState(item.cashonPickup);
  const [defaultStock, setdefaultStock] = useState(item.defaultStock);
  const [startTime, setStartTime] = useState(parseISO('2019-02-11T' + item.buyTime.Open));
  const [endTime, setEndTime] = useState(parseISO('2019-02-11T' + item.buyTime.Close.replace(" - ", "")));
  const [pickupstartTime, setpickupstartTime] = useState(parseISO('2019-02-11T' + item.pickup.Open));
  const [pickupendTime, setpickupendTime] = useState(parseISO('2019-02-11T' + item.pickup.Close.replace(" - ", "")));
  const [selectedDay, setselectedDay] = useState(item.day);
  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [visibleDate, setVisibleDate] = useState(false);
  const [visibleTime, setVisibleTime] = useState(false);
  const [visibleEndTime, setvisibleEndTime] = useState(false);

  const [pickupvisibleTime, setpickupvisibleTime] = useState(false);
  const [pickupvisibleEndTime, setpickupvisibleEndTime] = useState(false);

  useEffect(() => {
    console.log('price called');

  }, [])
  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Price"
        classTitle="title-green"
      >
        <div className={styles.price}>
          {/* <TextInput
          className={styles.field}
          label="Amount"
          name="amount"
          type="text"
          tooltip="Small description"
          required
          currency="$"
        /> */}
          {/* <div className={styles.line}>
          <div className={styles.info}>
            Allow customers to pay they want{" "}
            <Tooltip
              className={styles.tooltip}
              title="Maximum 100 characters. No HTML or emoji allowed"
              icon="info"
              place="top"
            />
          </div>
          <Switch
            className={styles.switch}
            value={resolution}
            onChange={() => setResolution(!resolution)}
          />
        </div> */}
          <div className={styles.fieldset}>
            <TextInput
              className={styles.field}
              classLabel={styles.label}
              label="Sale Price"
              name="sale-price"
              type="text"
              required
              currency="AED"
              defaultValue={salePrice}
              onChange={event => {
                ssalePrice(event.target.value)
                setsalePrice(event.target.value)
              }}
            />
            <TextInput
              className={styles.field}
              classLabel={styles.label}
              label="Actual Price"
              name="Actual-price"
              type="text"
              required
              currency="AED"
              defaultValue={actualPrice}
              onChange={event => {
                aactualPrice(event.target.value)
                setactualPrice(event.target.value)
              }}
            />
          </div>
        </div>
      </Card>

      <Card
        className={cn(styles.card, className)}
        title="Stocks"
        classTitle="title-green"
      >
        <div className={styles.price}>

          <div className={styles.fieldset}>
            <TextInput
              className={styles.field}
              classLabel={styles.label}
              label="Qty"
              name="qty"
              type="text"
              required
              defaultValue={quantity}
              onChange={event => {
                qquantity(event.target.value)
                setquantity(event.target.value)
              }}
            //currency="AED"
            />
            <TextInput
              className={styles.field}
              classLabel={styles.label}
              label="Default Stock"
              name="default-stock"
              type="text"
              required
              defaultValue={defaultStock}
              onChange={event => {
                ddefaultStock(event.target.value)
                setdefaultStock(event.target.value)
              }}
            //currency="AED"
            />
          </div>
        </div>
      </Card>

      <Card
        className={cn(styles.card, className)}
        //title="Stocks"
        classTitle="title-green"
      >
        <div className={styles.price}>

          <div className={styles.line}>
            <div className={styles.info}>
              Add Bag{" "}
            </div>
            <Switch
              className={styles.switch}
              value={addBag}
              onChange={() => {
                aaddBag(!addBag)
                setaddBag(!addBag)
              }}
            />
          </div>

          <div className={styles.line}>
            <div className={styles.info}>
              Cash on Pickup{" "}
            </div>
            <Switch
              className={styles.switch}
              value={cashonPickup}
              onChange={() => {
                ccashonPickup(!cashonPickup)
                setcashonPickup(!cashonPickup)
              }}
            />
          </div>
        </div>
      </Card>

      <Card
        className={cn(styles.card, className)}
        title="Schedule"
        classTitle="title-green"
      >
        <div className={sstyles.list}>
          <Dropdown
            className={sstyles.dropdown}
            classDropdownHead={sstyles.dropdownHead}
            value={selectedDay}
            setValue={(val) => {
              sselectedDay(val)
              setselectedDay(val)
            }}
            options={weekdays}
            small
          />
          <br />


          <div className={styles.info}>
              Buy Time{" "}
            </div>
          <Item
            className={sstyles.item}
            category="Open Time"
            icon="clock"
            value={startTime && format(startTime, "HH:mm")}
            visible={visibleTime}
            setVisible={setVisibleTime}
          >
            <div className={sstyles.time}>
              <div className={sstyles.top}>
                <div className={sstyles.subtitle}>
                  {startTime && format(startTime, "HH:mm")}
                </div>
                <button
                  className={sstyles.close}
                  onClick={() => setVisibleTime(false)}
                >
                  <Icon name="close" size="20" />
                </button>
              </div>
              <DatePicker
                selected={startTime}
                onChange={(date) => {
                  sstartTime(date)
                  setStartTime(date)
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption={false}
                dateFormat="HH:mm"
                inline
              />
            </div>
          </Item>

          <Item
            className={sstyles.item}
            category="Close Time"
            icon="clock"
            value={endTime && format(endTime, "HH:mm")}
            visible={visibleEndTime}
            setVisible={setvisibleEndTime}
          >
            <div className={sstyles.time}>
              <div className={sstyles.top}>
                <div className={sstyles.subtitle}>
                  {endTime && format(endTime, "HH:mm")}
                </div>
                <button
                  className={sstyles.close}
                  onClick={() => setvisibleEndTime(false)}
                >
                  <Icon name="close" size="20" />
                </button>
              </div>
              <DatePicker
                selected={endTime}
                onChange={(date) => {
                  eendTime(date)
                  setEndTime(date)
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption={false}
                dateFormat="HH:mm"
                inline
              />
            </div>
          </Item>


          <div className={styles.info}>
              Pickup Time{" "}
            </div>
          <Item
            className={sstyles.item}
            category="Open Time"
            icon="clock"
            value={pickupstartTime && format(pickupstartTime, "HH:mm")}
            visible={pickupvisibleTime}
            setVisible={setpickupvisibleTime}
          >
            <div className={sstyles.time}>
              <div className={sstyles.top}>
                <div className={sstyles.subtitle}>
                  {pickupstartTime && format(pickupstartTime, "HH:mm")}
                </div>
                <button
                  className={sstyles.close}
                  onClick={() => setpickupvisibleTime(false)}
                >
                  <Icon name="close" size="20" />
                </button>
              </div>
              <DatePicker
                selected={pickupstartTime}
                onChange={(date) => {
                  ppickupstartTime(date)
                  setpickupstartTime(date)
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption={false}
                dateFormat="HH:mm"
                inline
              />
            </div>
          </Item>

          <Item
            className={sstyles.item}
            category="Close Time"
            icon="clock"
            value={pickupendTime && format(pickupendTime, "HH:mm")}
            visible={pickupvisibleEndTime}
            setVisible={setpickupvisibleEndTime}
          >
            <div className={sstyles.time}>
              <div className={sstyles.top}>
                <div className={sstyles.subtitle}>
                  {pickupendTime && format(pickupendTime, "HH:mm")}
                </div>
                <button
                  className={sstyles.close}
                  onClick={() => setpickupvisibleEndTime(false)}
                >
                  <Icon name="close" size="20" />
                </button>
              </div>
              <DatePicker
                selected={pickupendTime}
                onChange={(date) => {
                  ppickupendTime(date)
                  setpickupendTime(date)
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption={false}
                dateFormat="HH:mm"
                inline
              />
            </div>
          </Item>
        </div>


      </Card>

    </>
  );
};

export default Price;
