import React, { useState } from "react";
import cn from "classnames";
import styles from "./Login.module.sass";
import Item from "../Item";
import TextInput from "../../../components/TextInput";
import { firebase } from '../../../Database_fb';

const Login = ({ className }) => {
  const [oldpasssword, setoldpasssword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [readOnly, setReadOnly] = useState(true);

  const changepassword = async () => {
    var user = firebase.auth().currentUser;
    const password = localStorage.getItem('password');

    if (password != oldpasssword) {
      alert("Old password is not correct")
      return
    }
    if (newpassword != confirmpassword) {
      alert("Please confirm Password")
      return
    }

    user.updatePassword(newpassword).then(function () {
      alert("Your password has been updated successfully")
      // Update successful.
    }).catch(function (error) {
      alert(error.message)
      // An error happened.
    });

  }
  return (
    <Item
      className={cn(styles.card, className)}
      title="Login"
      classTitle="title-purple"
    >
      <div className={styles.fieldset}>
        <TextInput
          autoComplete="disabled" readOnly={readOnly}
          onFocus={() => setReadOnly(false)}
          onBlur={() => setReadOnly(true)}
          className={styles.field}
          label="Old password"
          name="old-password"
          type="password"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={oldpasssword}
          onChange={event => setoldpasssword(event.target.value)}
        />
        <div className={styles.row}>
          <TextInput
          autoComplete="disabled" readOnly={readOnly}
          onFocus={() => setReadOnly(false)}
          onBlur={() => setReadOnly(true)}
            className={styles.field}
            label="New password"
            name="new-password"
            type="password"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
            value={newpassword}
            onChange={event => setnewpassword(event.target.value)}
          />
          <TextInput
          autoComplete="disabled" readOnly={readOnly}
          onFocus={() => setReadOnly(false)}
          onBlur={() => setReadOnly(true)}
            className={styles.field}
            label="Confirm new password"
            name="confirm-password"
            type="password"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
            value={confirmpassword}
            onChange={event => setconfirmpassword(event.target.value)}
          />
        </div>
        <button onClick={changepassword} className={cn("button-stroke", styles.button)}>
          Update password
        </button>
      </div>
    </Item>
  );
};

export default Login;
