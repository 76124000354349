import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import user_logo from "../../../images/placeholder-restaurant.png"
import EventEmitter from "reactjs-eventemitter";
import { getAuth, signOut } from "firebase/auth";
import { db } from '../../../Database_fb';

import StoreModal from "../../../components/StoreModal";
import StoreDetails from "../../../screens/Home/Overview/StoreDetails";
// const items = [
//   {
//     menu: [
//       // {
//       //   title: "Profile",
//       //   url: "/shop",
//       // },
//       {
//         title: "Edit profile",
//         url: "/settings",
//       },
//     ],
//   },
//   // {
//   //   menu: [
//   //     {
//   //       title: "Analytics",
//   //       icon: "bar-chart",
//   //       url: "/customers/overview",
//   //     },
//   //     {
//   //       title: "Affiliate center",
//   //       icon: "ticket",
//   //       url: "/affiliate-center",
//   //     },
//   //     {
//   //       title: "Explore creators",
//   //       icon: "grid",
//   //       url: "/explore-creators",
//   //     },
//   //   ],
//   // },
//   // {
//   //   menu: [
//   //     {
//   //       title: "Upgrade to Pro",
//   //       icon: "leaderboard",
//   //       color: true,
//   //       url: "/upgrade-to-pro",
//   //     },
//   //   ],
//   // },
//   {
//     menu: [
//       // {
//       //   title: "Account settings",
//       //   url: "/settings",
//       // },
//       {
//         title: "Log out",
//       },
//     ],
//   },
// ];

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const [userImg, setuserImg] = useState(null);
  const [items, setitems] = useState([
    {
      menu: [
        {
          title: "Edit profile",
          url: "/settings",
        },
      ],
    },
    {
      menu: [
        {
          title: "Log out",
        },
      ],
    },
  ]);
  const history = useHistory();


  useEffect(() => {
    EventEmitter.subscribe('Reload', event => {
      loadUser(event.value)
    })
    loadUser(false)
    // return () => {
    //   orders();
    // };
  }, [])

  function loadUser(multiple) {
    const storekey = localStorage.getItem('storekey');
    if (storekey) {
      var orders = db
        .collection("stores")
        .doc(storekey)
      orders.get().then((documentSnapshot) => {
        if (documentSnapshot) {
          let data = documentSnapshot.data();
          setuserImg(data.imageURL)
          if (multiple) {
            setitems([
              {
                menu: [
                  {
                    title: "Edit profile",
                    url: "/settings",
                  },
                ],
              },
              {
                menu: [
                  {
                    title: "Change Store",
                  },
                  {
                    title: "Log out",
                  },
                ],
              },
            ])
          }
        }
      });
    }
  }

  const handleSubmit = (title) => {

    if (title != 'Log out') {
      localStorage.setItem('showstores', "true");
      setVisible(false)
      history.push('/')
      EventEmitter.dispatch('showStores', { value: false })
    }
    else {
      const auth = getAuth();
      signOut(auth).then(() => {
        localStorage.clear();
        history.push('/')
        // EventEmitter.dispatch('Logout', { value: false })
        // history.goBack();
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage + " " + errorCode);
      });
      setVisible(false);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src={(userImg != null) ? userImg : user_logo} alt="Avatar" />
        </button>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, { [styles.color]: x.color })}
                    activeClassName={styles.active}
                    to={x.url}
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => handleSubmit(x.title)}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
