import React from "react";
import styles from "./Stores.module.sass";
import cn from "classnames";

const Stores = ({ item, className }) => {
  return (
    <div className={cn(styles.item, className)} style={{justifyContent:'space-evenly'}}>
      <div className={styles.preview}>
        <img src={item.imageURL} alt="Storeimage" />
      </div>
      <div className={styles.details}>
        <div className={styles.product}>{item.storeName}</div>
      </div>
    </div>
  );
};



export default Stores;
