import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Balance from "../../../../components/Balance";

const Row = ({
  item,
  value,
  onChange,
  activeTable,
  setActiveTable,
  activeId,
  setActiveId,
  selectedOrder
}) => {
  const handleClick = (item) => {
    setActiveTable(true);
    setActiveId(item.id);
    selectedOrder(item)
  };

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.col}>
          {/* <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          /> */}
        </div>
        <div className={styles.col}>
          <div className={styles.item} onClick={() => handleClick(item)} >
            
            <div className={styles.avatar}>
              <img src={item.dish.dishimageURL} alt="Avatar" />
            </div>
            <div className={styles.details}>
              <div className={styles.user}>{item.user.firstname + " " + item.user.lastname}</div>
              <div className={styles.login}>{item.order.dishName}</div>
              {/* <div className={styles.email}>{item.user.email}</div> */}
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>{item.user.phonevalue}</div>
        </div>
        <div className={styles.col}>
          <div className={cn("status-green-dark", styles.purchase)}>
            {item.order.totalPrice} {item.currency_symb}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price}>{item.order.Quantity}</div>
            {/* <Balance className={styles.balance} value={item.balance} /> */}
          </div>
        </div>
        <div className={styles.col}>{item.order.paymentmode}</div>
        <div className={styles.col}>{item.order.status}</div>
      </div>
    </>
  );
};

export default Row;
