import React, { useState,useEffect } from "react";
import cn from "classnames";
import styles from "./Drafts.module.sass";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Icon from "../../components/Icon";
import Table from "../../components/Table";
import Product from "../../components/Product";
import Loader from "../../components/Loader";
import Panel from "./Panel";
import { db, firebase } from '../../Database_fb';
import { useHistory } from "react-router-dom";
import EventEmitter from "reactjs-eventemitter";


// data
import { products } from "../../mocks/products";

const sorting = ["list", "grid"];

const Drafts = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [productsArray, setproductsArray] = useState([])
  const [loader, setloader] = useState(true);
  const history = useHistory();
  const handleSubmit = (e) => {
    alert();
  };

  useEffect(() => {
    // if (firebase.auth().currentUser == null) {
    //   EventEmitter.dispatch('Logout', { value: false })
    //    history.goBack();
    //   return;
    // }
  
    getAllProducts()
  }, [])

  function getAllProducts() {
    const storekey = localStorage.getItem('storekey');
    var products = db
      .collection("dishes")
      .where("restaurantId", "==", storekey)
    //.limit(20);
    products.get().then((documentSnapshot) => {
      if (documentSnapshot.size > 0) {
        let pros = []
        documentSnapshot.forEach(item => {
          var temp = item.data();
          pros.push(temp)
        });
        console.log('pros', pros);
        setproductsArray(pros)
        setloader(false)

      }
      setloader(false)
    });
  }

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <>
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title="Products"
        classTitle={cn("title-purple", styles.title)}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder="Search product"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.sorting}>
              {sorting.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  <Icon name={x} size="24" />
                </button>
              ))}
            </div>
          </>
        }
      >
        <div className={styles.wrapper}>
          {activeIndex === 0 && <Table items={productsArray} title="Last edited" Loading={loader}/>}
          {activeIndex === 1 && (
            <>
              <div className={styles.list}>
                {productsArray.map((x, index) => (
                  <Product
                    className={styles.product}
                    value={selectedFilters.includes(x.id)}
                    onChange={() => handleChange(x.id)}
                    item={x}
                    key={index}
                    released
                  />
                ))}
              </div>
              {loader ?
              <div className={styles.foot}>
                <button
                  className={cn("button-stroke button-small", styles.button)}
                >
                  <Loader className={styles.loader} />
                  <span>Load more</span>
                </button>
              </div> : null}
            </>
          )}
        </div>
      </Card>
      {/* <Panel /> */}
    </>
  );
};

export default Drafts;
