import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../Checkbox";
import ModalProduct from "../../ModalProduct";
import Icon from "../../Icon";
import Actions from "../../Actions";
import Modal from "../../Modal";
import Schedule from "../../Schedule";
import Control from "./Control";
import { useHistory } from "react-router-dom";
import { parseISO } from "date-fns";
const Row = ({ item, value, onChange }) => {
  const [startDate, setStartDate] = useState(Date());
  const [startTime, setStartTime] = useState(parseISO('2019-02-11T' + item.buyTime.Open));
  const [endTime, setEndTime] = useState(parseISO('2019-02-11T' + item.buyTime.Close.replace(" - ", "")));
  const [pickupstartTime, setpickupStartTime] = useState(parseISO('2019-02-11T' + item.pickup.Open));
  const [pickupendTime, setpickupEndTime] = useState(parseISO('2019-02-11T' + item.pickup.Close.replace(" - ", "")));
  const [selectedDay, setselectedDay] = useState(item.day);
  const history = useHistory();
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [visibleModalSchedule, setVisibleModalSchedule] = useState(false);

  const actions = [
    {
      title: "Schedule product",
      icon: "calendar",
      action: () => setVisibleModalSchedule(true),
    },
    {
      title: "Edit Dish",
      icon: "edit",
      action: () => {
        history.push('/products/add', { item: item })
      },
    },
    // {
    //   title: "Delete forever",
    //   icon: "trash",
    //   action: () => console.log("Delete forever"),
    // },
  ];

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {/* <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          /> */}
        </div>
        <div className={styles.col}>
          <div
            className={styles.item}
            onClick={() => setVisibleModalProduct(true)}
          >
            <div className={styles.preview}>
              <img
                //srcSet={`${item.image2x} 2x`}
                src={item.dishimageURL}
                alt="Product"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.dishName}</div>
              <div className={styles.link}>{item.description}</div>
              {/* <div className={styles.date}>
                <Icon name="clock" size="20" /> {item.date}
              </div> */}
              <div className={styles.price}>{item.SalePrice} {item.currency_symb}</div>
            </div>
          </div>
          <Actions
            className={styles.actions}
            classActionsHead={styles.actionsHead}
            items={actions}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.price}>{item.SalePrice} {item.currency_symb}</div>

        </div>
        <div className={styles.col} style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>
          <div  style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{item.actualPrice} AED</div>

        </div>
        <div className={styles.col}>
          {item.day}
          <Control
            className={styles.control}
            startDate={startDate}
            setStartDate={setStartDate}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}

            pickupstartTime={pickupstartTime}
            setpickupStartTime={setpickupStartTime}
            pickupendTime={pickupendTime}
            setpickupEndTime={setpickupEndTime}

            selectedDay={selectedDay}
            setSelectedDay={setselectedDay}
            item={item}
            action={() => setVisibleModalSchedule(false)}
          />
        </div>
      </div>
      <ModalProduct
        item={item}
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
      <Modal
        visible={visibleModalSchedule}
        onClose={() => setVisibleModalSchedule(false)}
      >
        <Schedule
          item={item}
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          selectedDay={selectedDay}
          pickupstartTime={pickupstartTime}
          setpickupStartTime={setpickupStartTime}
          pickupendTime={pickupendTime}
          setpickupEndTime={setpickupEndTime}
          
          action={() => setVisibleModalSchedule(false)}
          setSelectedDay={setselectedDay}
        />

      </Modal>
    </>
  );
};

export default Row;
