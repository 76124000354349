import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Item from "./Item";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Users from "../../../components/Users";
import Chart from "./Chart";
import Loader from "../../../components/Loader";
import StoreModal from "../../../components/StoreModal";
import user_logo from "../../../images/placeholder-restaurant.png"
import styles1 from "../../../components/Header/User/User.module.sass";
import StoreDetails from "./StoreDetails";

import { db, firebase } from '../../../Database_fb';
import moment from 'moment';
import EventEmitter from "reactjs-eventemitter";

const intervals = ["All time", "Today"];

const nav = [
  {
    title: "Orders",
    counter: "0",
    icon: "shopping-bag",
    color: "#B1E5FC",
    value: -37.8,
  },
  {
    title: "Income",
    counter: "0 AED",
    icon: "activity",
    color: "#CABDFF",
    value: 37.8,
  },
];

const Overview = ({ className }) => {
  const [sorting, setSorting] = useState(intervals[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [overview, setoverview] = useState(nav);
  const [visibleModal, setVisibleModal] = useState(false);
  const [StoresData, setStoresData] = useState([]);

  useEffect(() => {
    const firstTime = localStorage.getItem('firstTime');
    if (!firstTime) {
      loadStore()
      localStorage.setItem('firstTime', "loaded");
      EventEmitter.subscribe('showStores', event => {
          loadStore()
      })
    }
    else{
      const multiple = localStorage.getItem('multiple');
      const showStores = localStorage.getItem('showstores');
      if (showStores == 'true') {
        loadStore()
        localStorage.removeItem('showstores')
      }
      else{
      EventEmitter.dispatch('Reload', { value: (multiple == 'true')})
      getOverView(true)
      EventEmitter.subscribe('showStores', event => {
          loadStore()
      })
      }
    }
  

  }, [])

  function SelectedStore(item) {
    setVisibleModal(false)
    localStorage.setItem('storekey', item.key);
    EventEmitter.dispatch('Reload', { value: true })
    getOverView(true)
    
  }
  function loadStore() {
    const email = localStorage.getItem('email');
    var store = db
      .collection("stores")
      .where("storeEmail", "==", email)
    store.get().then((documentSnapshot) => {
      var stores = [];
      if (documentSnapshot.size > 0) {
        documentSnapshot.forEach(snap => {
          let data = snap.data();
          stores.push(data)
        })
        if (documentSnapshot.size > 1) {
          localStorage.setItem('multiple', "true");
          setStoresData(stores)
          setTimeout(() => {
            setVisibleModal(true)
          }, 500);
        }
        else{
          localStorage.removeItem('multiple');
          localStorage.setItem('storekey', stores[0].key);
          EventEmitter.dispatch('Reload', { value: false })
          getOverView(true)
        }
      }
    });
  }

  function getOverView(all) {
    const storekey = localStorage.getItem('storekey');
    var orders;
    if (all) {
      orders = db.collection("orders")
        .where("restaurantId", "==", storekey)
      //.limit(20);
    }
    else {
      let todayDate = moment().format("YYYY-MM-DD")
      orders = db.collection("orders")
        .where("pickupDate", "==", todayDate)
        .where("restaurantId", "==", storekey)
    }
    orders.get().then((documentSnapshot) => {
      setData(documentSnapshot)
    });
  }

  function setData(documentSnapshot) {
    let valueArray = []
    let total = 0
    if (documentSnapshot.size > 0) {
      valueArray.push({
        title: "Orders",
        counter: documentSnapshot.size,
        icon: "shopping-bag",
        color: "#B1E5FC",
        value: 0,
      })

      documentSnapshot.forEach(item => {
        var temp = item.data();
        total = total + temp.totalPrice
      });
      if (isNaN(total)) {
        total = 0
      }
      valueArray.push({
        title: "Income",
        counter: total + " AED",
        icon: "activity",
        color: "#CABDFF",
        value: 37.8,
      })
      setoverview(valueArray)
    }
    else {
      setoverview(nav)
    }
  }

  return (
    <>
         <Card
          className={cn(styles.card, className)}
          title="Overview"
          classTitle="title-red"
          head={
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={sorting}
              setValue={(val) => {
                console.log('sort val', val);
                getOverView((val == 'Today') ? false : true)
                setSorting(val)
              }}
              options={intervals}
              small
            />
          }
        >
          <div className={styles.overview}>
            <div className={styles.nav}>
              {overview.map((x, index) => (

                <Item
                  className={cn(styles.item, {
                    [styles.active]: index === activeIndex,
                  })}
                  key={index}
                  onActive={() => setActiveIndex(index)}
                  item={x}
                />

              ))}
            </div>
            {/* <div className={styles.body}>
          {activeIndex === 0 && <Users />}
          {activeIndex === 1 && <Chart />}
        </div> */}
          </div>
        </Card> 
        <StoreModal
          outerClassName={styles.outer}
          visible={visibleModal}
        >
          {StoresData.map((x, index) => (
              <StoreDetails key={index}  item={x} onClick={SelectedStore}/>
          ))}
        </StoreModal> 
    </>
  );
};

export default Overview;
