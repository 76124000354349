import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../Icon";
import ModalProduct from "../../ModalProduct";
import { useHistory } from "react-router-dom";

const Control = ({ item,className }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const history = useHistory();
  const actions = [
    {
      icon: "edit",
      action: () => {
        history.push('/products/add',{item: item})
      },
    },
    // {
    //   icon: "trash",
    //   action: () => console.log("remove"),
    // },
    // {
    //   icon: "arrow-right",
    //   action: () => {
       
    //     setVisibleModalProduct(true)
    //   },
    // },
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            <Icon name={x.icon} size="20" />
          </button>
        ))}
      </div>
      <ModalProduct
        item={item}
        visible={visibleModalProduct}
        onClose={() => {
          console.log('close called');
          setVisibleModalProduct(false)
        }}
      />
    </>
  );
};

export default Control;
