import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link,NavLink } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import hifz_logo from "../../images/logo-dark.png"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { db, firebase } from '../../Database_fb';
import { useAlert } from 'react-alert'
//import { NavLink,useHistory } from "react-router-dom";


const SignIn = ({ setAuth }) => {
  const heightWindow = use100vh();
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const alert = useAlert()
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }


  const logInWithEmailAndPassword = async () => {


    console.log('clicked');
    if (!isValidEmail(email)) {
      //alert("Email is invalid")
      alert.error('Email is invalid', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }
    else if (password.length == 0) {
      //alert("Provide a valid password")
      alert.error('Provide a valid password', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email.toLowerCase(), password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        if (user != null) {
          
          localStorage.setItem('email', email.toLowerCase());
          localStorage.setItem('password', password);
          // var store = db
          //   .collection("stores")
          //   .where("storeEmail", "==", email)
          // store.get().then((documentSnapshot) => {
          //   //var stores = [];
          //   if (documentSnapshot.size > 0) {
          //     documentSnapshot.forEach(snap => {
          //       let data = snap.data();
          //       //stores.push(data)
          //      localStorage.setItem('storekey', data.key);
          //     });
              // if (stores.length > 1) {
              //   setStoresData(stores)
              //   setTimeout(() => {
              //     setVisibleModal(true)
              //   }, 500);
              // }
              setAuth(true);
            //}
        //  });
        }
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //alert(errorMessage + " " + errorCode);
        alert.error(errorMessage + " " + errorCode, {
          timeout: 3000,
          position: 'middle',
        })
      });
    // firebase.auth().signInWithEmailAndPassword(auth, email, password).then((loggedInUser) => {
    //     console.log("Login Successful!", loggedInUser);

    //     navigate('Home')
    // }).catch((eror) => {
    //     alert(eror.message)
    // });;

  };


  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src={hifz_logo}
            srcDark={hifz_logo}
            alt="Hifz"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign in</div>
        <div className={styles.head}>
          <div className={styles.subtitle}>Sign in with the provided details</div>
          {/* <div className={styles.btns}> */}
          {/* <button className={cn("button-stroke", styles.button)}>
              <img src="/images/content/google.svg" alt="Google" />
              Google
            </button> */}
          {/* <button className={cn("button-stroke", styles.button)}>
              <Image
                className={styles.pic}
                src="/images/content/apple-dark.svg"
                srcDark="/images/content/apple-light.svg"
                alt="Apple"
              />
              Apple ID
            </button> */}
          {/* </div> */}
        </div>
        <div className={styles.body}>
          {/* <div className={styles.subtitle}>Sign in with the provided details</div> */}
          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder="Your email"
            required
            icon="mail"
            onChange={(val) => {
              setemail(val.target.value)
            }}
          />
          <TextInput
            className={styles.field}
            name="password"
            type="password"
            placeholder="Password"
            required
            icon="lock"
            onChange={(val) => {
              setpassword(val.target.value)
            }}
          />
          <button className={cn("button", styles.button)} onClick={logInWithEmailAndPassword}>Sign in</button>
          <div className={styles.note}>

          </div>
          {/* <div className={styles.info}>
            Don’t have an account?{" "}
            <Link className={styles.link} to="/sign-up">
              Sign up
            </Link>
          </div> */}
        </div>
      </div>
     
    </div>
  );
};

export default SignIn;
