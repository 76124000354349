import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import TextArea from "../../../components/TextArea";
import Editor from "../../../components/Editor";
import { db, firebase } from '../../../Database_fb';
import user_logo from "../../../images/placeholder-restaurant.png"

const ProfileInformation = ({ className, sstorename, ccontactemail, pphonenumber, sstoredescription, ffbusername, iinstausername, ttwiterusername, ssnapusername, uuserImg ,iimagechanged, filee}) => {
  const [content, setContent] = useState();
  const [storename, setstorename] = useState("");
  const [contactemail, setcontactemail] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [storedescription, setstoredescription] = useState("");
  const [fbusername, setfbusername] = useState("");
  const [instausername, setinstausername] = useState("");
  const [twiterusername, settwiterusername] = useState("");
  const [snapusername, setsnapusername] = useState("");
  const [userImg, setuserImg] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  useEffect(() => {
    const storekey = localStorage.getItem('storekey');
    var store = db
      .collection("stores")
      .doc(storekey)
    store.get().then((documentSnapshot) => {
      if (documentSnapshot) {
        let data = documentSnapshot.data();
        setuserImg(data.imageURL)
        setstorename(data.storeName)
        setcontactemail(data.contactEmail)
        setphonenumber(data.phoneNumber)
        setstoredescription(data.storeDescription)
        setfbusername(data.fbLink)
        setinstausername(data.instaLink)
        settwiterusername(data.twitterlink)
        setsnapusername(data.snapchatlink)

        uuserImg(data.imageURL)
        sstorename(data.storeName)
        ccontactemail(data.contactEmail)
        pphonenumber(data.phoneNumber)
        sstoredescription(data.storeDescription)
        ffbusername(data.fbLink)
        iinstausername(data.instaLink)
        ttwiterusername(data.twitterlink)
        ssnapusername(data.snapchatlink)
      }
    });
   

  }, [])

  return (
    <Item
      className={cn(styles.card, className)}
      title="Profile information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <img src={(userImg != null) ? userImg : user_logo} alt="Avatar" />
          <button className={styles.remove}>
            <Icon name="close" />
          </button>
        </div>
        <div className={styles.file}>
          <input type="file" onChange={event => {
            if (event.target.files && event.target.files[0]) {
              let imgurl = URL.createObjectURL(event.target.files[0])
              filee(event.target.files[0])
              uuserImg(imgurl)
              iimagechanged(true)
              setuserImg(imgurl)
            }
          }}
            accept="image/*" />
          <button className={cn("button", styles.button)} type="button">
            <Icon name="add" size="24" />
            <span>Upload new picture</span>
          </button>
        </div>
        {/* <button className={cn("button-stroke", styles.button)}>Remove</button> */}
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Store name"
          name="display-name"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          onChange={event => {
            sstorename(event.target.value)
            setstorename(event.target.value)
          }}
          defaultValue={storename}
        />
        <TextInput
          className={styles.field}
          label="Contact Email"
          name="email"
          type="email"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={contactemail}
          onChange={event => {
            ccontactemail(event.target.value)
            setcontactemail(event.target.value)
          }}
        //defaultValue={storename}
        />
        <TextInput
          className={styles.field}
          label="Phone number"
          name="phone"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={phonenumber}
          onChange={event => {
            pphonenumber(event.target.value)
            setphonenumber(event.target.value)
          }}
        />

        <TextArea
          className={styles.field}
          label="Store Description"
          name="Description"
          type="text"
          tooltip="Maximum 500 characters. No HTML or emoji allowed"
          required
          value={storedescription}
          onChange={event => {
            sstoredescription(event.target.value)
            setstoredescription(event.target.value)
          }}
        />
        {/* <Editor
          //state={storedescription}
          onChange={setstoredescription}
          classEditor={styles.editor}
          label="Store Description"
          tooltip="Description"
          value={storedescription}
          //onChange={event => setstoredescription(event.target.value)}
        /> */}

        <TextInput
          className={styles.field}
          label="FB username"
          name="fb"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={fbusername}
          onChange={event => {
            ffbusername(event.target.value)
            setfbusername(event.target.value)
          }}
        />
        <TextInput
          className={styles.field}
          label="Instagram username"
          name="insta"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={instausername}
          onChange={event => {
            iinstausername(event.target.value)
            setinstausername(event.target.value)
          }}
        />

        <TextInput
          className={styles.field}
          label="Twitter username"
          name="twitter"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={twiterusername}
          onChange={event => {
            ttwiterusername(event.target.value)
            settwiterusername(event.target.value)
          }}
        />

        <TextInput
        autoComplete="disabled" readOnly={readOnly}
        onFocus={() => setReadOnly(false)}
        onBlur={() => setReadOnly(true)}
          className={styles.field}
          label="Snapchat username"
          name="snap"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={snapusername}
          onChange={event => {
            ssnapusername(event.target.value)
            setsnapusername(event.target.value)
          }}
        />

      </div>
    </Item>
  );
};

export default ProfileInformation;
