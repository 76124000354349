import React, { useState } from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "./Control";
import Icon from "../Icon";
import Checkbox from "../Checkbox";
import ModalProduct from "../../components/ModalProduct";

const Product = ({
  className,
  item,
  value,
  onChange,
  released,
  withoutСheckbox,
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleModalProduct, setvisibleModalProduct] = useState(false);

  const handleClick = () => {
    onChange();
    setVisible(!visible);
  };

  return (
    <div
      className={cn(styles.product, className, { [styles.active]: visible })}
      // onClick={() => setvisibleModalProduct(true)}
    >
      <div className={styles.preview}>
        {/* {!withoutСheckbox && (
          <Checkbox
            className={styles.checkbox}
            classCheckboxTick={styles.checkboxTick}
            value={value}
            onChange={() => handleClick()}
          />
        )} */}
        <Control item={item} className={styles.control} />
        <img src={item.dishimageURL} alt="Product" />
      </div>
      <div className={styles.line}>
        <div className={styles.title}>{item.dishName}</div>
        <div className={styles.price}>{item.SalePrice} {item.currency_symb}</div>

      </div>
      {/* {released ? ( */}
      <div className={styles.date}>
        <Icon name="clock" size="24" /> {item.day}
      </div>
      {/* ) : item.ratingValue ? (
        <div className={styles.rating}>
          <Icon name="star-fill" size="24" />
          {item.ratingValue}{" "}
          <span className={styles.counter}>({item.ratingCounter})</span>
        </div>
      ) : (
        <div className={cn(styles.rating, styles.ratingEmpty)}>
          <Icon name="star-stroke" size="24" />
          No ratings
        </div>
      )} */}

      <ModalProduct
        item={item}
        visible={visibleModalProduct}
        onClose={() => {
          console.log('callime');
          setvisibleModalProduct(false)
        }}
      />
    </div>
  );
};

export default Product;
