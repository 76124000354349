import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import { useHistory } from "react-router-dom";
import TextArea from "../../../components/TextArea";

const NameAndDescription = ({item, className, ddishName, sstoreDescription }) => {
  const [content, setContent] = useState();
  const [dishName, setdishName] = useState(item.dishName);
  const [storeDescription, setstoreDescription] = useState(item.description);
  const history = useHistory();
  return (
    <Card
      className={cn(styles.card, className)}
      title="Name & description"
      classTitle="title-green"
      head={
        <Link
          className={cn("button-stroke button-small", styles.button)}
          to="/products"
        >
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </Link>
      }
    >
      <div className={styles.description}>
        <TextInput
          className={styles.field}
          label="Product title"
          name="title"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          onChange={event => {
            ddishName(event.target.value)
            setdishName(event.target.value)
          }}
          defaultValue={dishName}
        />
        {/* <Editor
          state={content}
          onChange={setContent}
          classEditor={styles.editor}
          label="Description"
          tooltip="Description"
        /> */}
         <TextArea
          className={styles.field}
          label="Store Description"
          name="Description"
          type="text"
          tooltip="Maximum 500 characters. No HTML or emoji allowed"
          required
          value={storeDescription}
          onChange={event => {
            sstoreDescription(event.target.value)
            setstoreDescription(event.target.value)
          }}
        />
        {/* <div className={styles.group}>
          <TextInput
            className={styles.field}
            label="Key features"
            name="value1"
            type="text"
            placeholder="Value"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            required
          />
          <TextInput
            className={styles.field}
            name="value2"
            type="text"
            placeholder="Value"
            required
          />
          <TextInput
            className={styles.field}
            name="value3"
            type="text"
            placeholder="Value"
            required
          />
          <TextInput
            className={styles.field}
            name="value4"
            type="text"
            placeholder="Value"
            required
          />
        </div> */}
      </div>
    </Card>
  );
};

export default NameAndDescription;
