import React, { useState, useRef } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Dropdown from "../../components/Dropdown";
import ProfileInformation from "./ProfileInformation";
import Login from "./Login";
import Notifications from "./Notifications";
import Payment from "./Payment";
import { db, storage } from '../../Database_fb';

import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";

import { useAlert } from 'react-alert'
import { type } from "@testing-library/user-event/dist/type";

const Settings = () => {
  const navigation = [
    {
      title: "Basics",
      action: () =>
        scrollToProfile.current.scrollIntoView({ behavior: "smooth" }),
    },
    {
      title: "Account",
      action: () =>
        scrollToLogin.current.scrollIntoView({ behavior: "smooth" }),
    },
    // {
    //   title: "Notifications",
    //   action: () =>
    //     scrollToNotifications.current.scrollIntoView({ behavior: "smooth" }),
    // },
    // {
    //   title: "Payment",
    //   action: () =>
    //     scrollToPayment.current.scrollIntoView({ behavior: "smooth" }),
    // },
  ];
  const options = [];
  navigation.map((x) => options.push(x.title));
  const [activeTab, setActiveTab] = useState(options[0]);
  const alert = useAlert()
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollToProfile = useRef(null);
  const scrollToLogin = useRef(null);
  const scrollToNotifications = useRef(null);
  const scrollToPayment = useRef(null);
  const [file, setFile] = useState("");
  const [storename, setstorename] = useState("");
  const [contactemail, setcontactemail] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [storedescription, setstoredescription] = useState("");
  const [fbusername, setfbusername] = useState("");
  const [instausername, setinstausername] = useState("");
  const [twiterusername, settwiterusername] = useState("");
  const [snapusername, setsnapusername] = useState("");
  const [userImg, setuserImg] = useState(null);
  const [imageChanged, setimageChanged] = useState(false);

  const handleClick = (x, index) => {
    setActiveIndex(index);
    x.action();
  };


  function saveData() {
    const storekey = localStorage.getItem('storekey');

    if (storename.length == 0) {
     // alert("Store name is required")
      alert.error('Store name is required', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }
    if (contactemail.length == 0) {
     //alert("Contact email is required")
      alert.error('Contact email is required', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }

    if (imageChanged) {
      uploadImage(storekey) 
    }
    db.collection("stores")
      .doc(storekey)
      .update({
        storeName: storename,
        contactEmail: contactemail,
        phoneNumber: phonenumber,
        storeDescription: storedescription,
        fbLink: fbusername,
        instaLink: instausername,
        twitterlink: twiterusername,
        snapchatlink: snapusername
      })
      .then(() => {
        //alert("Profile data updated successfully")
        alert.success('Profile data updated successfully', {
          timeout: 3000,
        })
      });
  }

  function uploadImage(storekey) {
    // const Profile_Image = storage.ref(`stores/${storekey}/Pic_${storekey}.jpeg`);
    // const task = Profile_Image.putFile(userImg);
    // task.then(async () => {
    //   const profile_link = (await Profile_Image.getDownloadURL()).toString();
    //   db.collection("stores").doc(storekey).update({
    //     imageURL: profile_link,
    //   }).then(() => {
    //     console.log("User updated with photo!");
    //   }).catch((error) => {
    //     console.log("error", error);
    //   });
    // }).catch((error) => {
    //   console.log(" error", error);
    // });

    const storageRef = ref(storage, `stores/${storekey}/Pic_${storekey}.jpeg`);
        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                // update progress
               // setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);
                    db.collection("stores").doc(storekey).update({
                      imageURL: url.toString(),
                    }).then(() => {
                      console.log("User updated with photo!");
                    }).catch((error) => {
                      console.log("error", error);
                    });
                });
            }
        );
  }

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.button, {
                [styles.active]: activeIndex === index,
              })}
              key={index}
              onClick={() => handleClick(x, index)}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={options}
          />
          <div className={styles.list}>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[0],
              })}
            >
              <div className={styles.anchor} ref={scrollToProfile}></div>
              <ProfileInformation sstorename={(event) => {
                console.log(event);
                setstorename(event)
              }} ccontactemail={(val) => {
                setcontactemail(val)
              }} pphonenumber={(val) => {
                setphonenumber(val)
              }} sstoredescription={(val) => {
                setstoredescription(val)
              }}
                ffbusername={(val) => {
                  setfbusername(val)
                }} iinstausername={(val) => {
                  setinstausername(val)
                }} ttwiterusername={(val) => {
                  settwiterusername(val)
                }} ssnapusername={(val) => {
                  setsnapusername(val)
                }}
                uuserImg={(val) => {
                  setuserImg(val)
                }}
                iimagechanged={(val) => {
                  setimageChanged(val)
                }}
                filee={(val) => {
                  setFile(val)
                }}
              />
            </div>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[1],
              })}
            >
              <div className={styles.anchor} ref={scrollToLogin}></div>
              <Login />
            </div>
            {/* <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[2],
              })}
            >
              <div className={styles.anchor} ref={scrollToNotifications}></div>
              <Notifications />
            </div> */}
            {/* <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[3],
              })}
            >
              <div className={styles.anchor} ref={scrollToPayment}></div>
              <Payment />
            </div> */}
          </div>
          <button onClick={saveData} className={cn("button", styles.button)}>Save</button>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Settings;
