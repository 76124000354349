import React, { useState, useEffect } from "react";
import styles from "./CustomerList.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Filters from "../../components/Filters";
import Settings from "./Settings";
import Table from "./Table";
import Panel from "./Panel";
import Details from "./Details";
import { db, firebase } from '../../Database_fb';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import EventEmitter from "reactjs-eventemitter";
import { tr } from "date-fns/locale";
const navigation = ["Not Picked up", "All"];

const CustomerList = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [ordersArray, setordersArray] = useState([])
  const [NOPordersArray, setNOPordersArray] = useState([])
  const [loader, setloader] = useState(true);
  const [nothingFound, setnothingFound] = useState(false);
  const history = useHistory();
  const handleSubmit = (e) => {
    alert();
  };

  useEffect(() => {
    // if (firebase.auth().currentUser == null) {
    //   EventEmitter.dispatch('Logout', { value: false })
    //    history.goBack();
    //   return;
    // }
    FetchOrders()
  }, [])


  function FetchOrders() {
    let jArray = [];
    let notPickedArry = [];
    let badge = 0;
    let todayDate = moment().format("YYYY-MM-DD");
    const storekey = localStorage.getItem('storekey');
    //console.log('storekey',storekey);
    db.collection("orders")
      .where("pickupDate", "==", todayDate)
      .where("restaurantId", "==", storekey)
      .orderBy('timestamp')
      .get()
      .then(async (doc) => {
        var exists = (doc.size != 0);

        if (!exists) {
          setnothingFound(true)
          setloader(false)
          return;
        }
        let promises = [];
        let Dishpromises = [];
        let itempromises = [];
        doc.forEach(element => {
          let docid = element.id
          var order = element.data();
          itempromises.push({id : docid, ...order});
          promises.push(db.collection("users").doc(order.uid).get())
          Dishpromises.push(db.collection("dishes").doc(order.productid).get())
        })
        return Promise.all(promises).then(async values => {
          const pro = await Promise.all(Dishpromises);
          return [values, itempromises, pro];
        });

      }).then(snapshots => {
        const userSnaps = snapshots[0]
        const temps = snapshots[1]
        const dishes = snapshots[2]


        let index = 0
        userSnaps.forEach(userSnapshot => {
          var temp = temps[index]
          var dish = dishes[index].data()
          var user = userSnapshot.data();

          if (dish == null) {
            dish = { dishimageURL: 'https://firebasestorage.googleapis.com/v0/b/morrow-uae.appspot.com/o/dishImage.png?alt=media&token=a65088dc-6cfe-49a9-918c-1db0d95537ff' }
          }
          if (user == null) {
            user = { firstname: 'Unknown', lastname: '', phonevalue: '' }
          }
          if (temp.status == 'pending') {
            notPickedArry.push({ user: user, order: temp, dish: dish, id: index });
          }
          jArray.push({ user: user, order: temp, dish: dish, id: index });
          index = index + 1
        })
        let reversedOrderArray = jArray.reverse()
        let reversedNOPOrdersArray = notPickedArry.reverse()


        setNOPordersArray(reversedNOPOrdersArray)
        setordersArray(reversedOrderArray)
        setnothingFound(false)
        setloader(false)
      })
  }

  // function getNotPickedOrders() {
  //   const storekey = localStorage.getItem('storekey');
  //   let todayDate = moment().format("YYYY-MM-DD")
  //   var orders = db
  //     .collection("orders")
  //     .where("status", "==", 'pending')
  //     .where("restaurantId", "==", storekey)
  //   // .limit(20);
  //   orders.get().then((documentSnapshot) => {
  //     if (documentSnapshot.size > 0) {
  //       let orders = []
  //       documentSnapshot.forEach(item => {
  //         var temp = item.data();
  //         orders.push(temp)
  //       });
  //       setordersArray(orders)

  //     }
  //     setloader(false)
  //   });
  // }

  // function getAllOrders() {
  //   const storekey = localStorage.getItem('storekey');
  //   var orders = db
  //     .collection("orders")
  //     // .where("pickupDate", "==", todayDate)
  //     .where("restaurantId", "==", storekey)
  //   //.limit(20);
  //   orders.get().then((documentSnapshot) => {
  //     if (documentSnapshot.size > 0) {
  //       let orders = []
  //       documentSnapshot.forEach(item => {
  //         var temp = item.data();
  //         orders.push(temp)
  //       });
  //       console.log('orders', orders);
  //       setordersArray(orders)
  //       setloader(false)

  //     }
  //     setloader(false)
  //   });
  // }

  return (
    <>
      <Card
        className={styles.card}
        title="Today"
        classTitle={cn("title-purple", styles.title)}
        classCardHead={cn(styles.head, { [styles.hidden]: visible })}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder="Search by name or email"
              type="text"
              name="search"
              icon="search"
            />
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => {
                    setActiveIndex(index)
                    // if (index == 0) {
                    //   getNotPickedOrders()
                    // }
                    // else {
                    //   getAllOrders()
                    // }
                  }}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            {/* <Filters
              className={styles.filters}
              title="Showing 10 of 24 customer"
            >
              <Settings />
            </Filters> */}
          </>
        }
      >
        <div className={cn(styles.row, { [styles.flex]: visible })}>
          <Table
            className={styles.table}
            activeTable={visible}
            setActiveTable={setVisible}
            orders={(activeIndex == 0) ? NOPordersArray : ordersArray}
            Loading={loader}
            selectedOrder={setSelectedOrder}

          />
          <Details
            selectedOrder={selectedOrder}
            className={styles.details}
            onClose={() => setVisible(false)}
            refreshList={() => {
              FetchOrders()
            }}

          />
        </div>
        {nothingFound ? <span style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '10vh',
        }}>No order found</span> : null }
      </Card>

      {/* <Panel /> */}
    </>
  );
};

export default CustomerList;
