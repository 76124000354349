import React, { useState,useEffect } from "react";
import styles from "./NewProduct.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Modal from "../../components/Modal";
import Schedule from "../../components/Schedule";
import NameAndDescription from "./NameAndDescription";
import ImagesAndCTA from "./ImagesAndCTA";
import Price from "./Price";
import CategoryAndAttibutes from "./CategoryAndAttibutes";
import ProductFiles from "./ProductFiles";
import Discussion from "./Discussion";
import Preview from "./Preview";
import Panel from "./Panel";
import { useLocation, useHistory } from "react-router-dom";
import { db, storage } from '../../Database_fb';
import { format, parseISO, parse } from "date-fns";
import { useAlert } from 'react-alert'
import {
  ref,
  uploadBytesResumable,
  getDownloadURL 
} from "firebase/storage";

const NewProduct = () => {
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
 const alert = useAlert()
  const location = useLocation();
  const history = useHistory();
  const item = location.state.item;
  const [file, setFile] = useState("");
  const [salePrice, setsalePrice] = useState(location.state.item.SalePrice);
  const [actualPrice, setactualPrice] = useState(location.state.item.actualPrice);
  const [quantity, setquantity] = useState(location.state.item.Quantity);
  const [addBag, setaddBag] = useState(location.state.item.addBag);
  const [bagPrice, setbagPrice] = useState(location.state.item.bagPrice);
  const [cashonPickup, setcashonPickup] = useState(location.state.item.cashonPickup);
  const [defaultStock, setdefaultStock] = useState(location.state.item.defaultStock);
  const [startTime, setStartTime] = useState(parseISO('2019-02-11T' + location.state.item.buyTime.Open));
  const [endTime, setEndTime] = useState(parseISO('2019-02-11T' + location.state.item.buyTime.Close.replace(" - ", "")));

  const [pickupstartTime, setpickupStartTime] = useState(parseISO('2019-02-11T' + location.state.item.pickup.Open));
  const [pickupendTime, setpickupEndTime] = useState(parseISO('2019-02-11T' + location.state.item.pickup.Close.replace(" - ", "")));
  const [selectedDay, setselectedDay] = useState(location.state.item.day);
  const [dishName, setdishName] = useState(location.state.item.dishName);
  const [storeDescription, setstoreDescription] = useState(location.state.item.description);
  const [userImg, setuserImg] = useState(location.state.item.dishimageURL);
  const [imageChanged, setimageChanged] = useState(false);

  useEffect(() => {
    //console.log('item',item);
    console.log('startTime',startTime);
    console.log('endTime',endTime);
    console.log('pickupstartTime',pickupstartTime);
    console.log('pickupendTime',pickupendTime);
   // console.log(format(startTime, "HH:mm"));
    
    
  }, [])


  function saveData() {
    const storekey = localStorage.getItem('storekey');

    if (dishName.length == 0) {
     // alert("Dish name is required")
      alert.error('Dish name is required', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }
    
    if (imageChanged) {
      uploadImage() 
    }

    let Close = " - " + format(pickupendTime, "HH:mm")

        let picki = { Open: format(pickupstartTime, "HH:mm"), Close: Close }

        let bClose = " - " + format(endTime, "HH:mm")

        let buyi = { Open: format(startTime, "HH:mm"), Close: bClose }


    db.collection("dishes")
      .doc(item.productKey)
      .update({
            dishName: dishName,
            actualPrice: actualPrice,
            Quantity: quantity,
            SalePrice: salePrice,
            bagPrice: bagPrice,
            defaultStock: defaultStock,
            description: storeDescription,
            addBag: addBag,
            cashonPickup: cashonPickup,
            dishimageURL: userImg,
            pickup: picki,
            buyTime: buyi,
      })
      .then(() => {
        // setdishName(dishName)
        // setuserImg(userImg)
        // setsalePrice(salePrice)
       // alert("Dish data updated successfully")
        alert.success('Dish data updated successfully', {
          timeout: 3000,
         //position: 'middle',
        })
        history.goBack()
      });
  }
  function uploadImage() {
    // const Profile_Image = storage.ref(`products/${item.productKey}/dish/Pic_${dishName}.jpeg`);
    // const task = Profile_Image.putFile(userImg);
    // task.then(async () => {
    //   const profile_link = (await Profile_Image.getDownloadURL()).toString();
    //   db.collection("dishes").doc(item.productKey).update({
    //     dishimageURL: profile_link,
    //   }).then(() => {
    //     console.log("User updated with photo!");
    //   }).catch((error) => {
    //     console.log("error", error);
    //   });
    // }).catch((error) => {
    //   console.log(" error", error);
    // });

    const storageRef = ref(storage, `products/${item.productKey}/dish/Pic_${dishName}.jpeg`);
    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // update progress
           // setPercent(percent);
        },
        (err) => console.log(err),
        () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                console.log('imageurl',url);
                console.log('imageurl string',url.toString());
                db.collection("dishes").doc(item.productKey).update({
                  dishimageURL: url.toString(),
                }).then(() => {
                  console.log("User updated with photo!");
                }).catch((error) => {
                  console.log("error", error);
                });
            });
        }
    );

  }

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <NameAndDescription item={item}  className={styles.card} ddishName={(event) => {
                setdishName(event)
              }} sstoreDescription={(event) => {
                setstoreDescription(event)
              }}/>
          <ImagesAndCTA item={item} className={styles.card} uuserImg={(event) => {
                setuserImg(event)
              }} iimagechanged={(val) => {
                setimageChanged(val)
              }}
              filee={(event) => {
                setFile(event)
              }}
              />
          <Price item={item} className={styles.card} ssalePrice={(event) => {
                setsalePrice(event)
              }} aactualPrice={(event) => {
                setactualPrice(event)
              }} qquantity={(event) => {
                setquantity(event)
              }} ddefaultStock={(event) => {
                setdefaultStock(event)
              }} aaddBag={(event) => {
                setaddBag(event)
              }} ccashonPickup={(event) => {
                setcashonPickup(event)
              }} sstartTime={(event) => {
                setStartTime(event)
              }} eendTime={(event) => {
                setEndTime(event)
              }} ppickupstartTime={(event) => {
                setpickupStartTime(event)
              }} ppickupendTime={(event) => {
                setpickupEndTime(event)
              }} sselectedDay={(event) => {
                setselectedDay(event)
              }}/>
          {/* <CategoryAndAttibutes item={item} className={styles.card} />
          <ProductFiles item={item} className={styles.card} />
          <Discussion item={item} className={styles.card} /> */}
        </div>
        {/* <div className={styles.col}> */}
          {/* <Preview
          item={item}
            visible={visiblePreview}
            onClose={() => setVisiblePreview(false)}
          /> */}
        {/* </div> */}
      </div>

      <Panel
        setVisiblePreview={setVisiblePreview}
        setVisibleSchedule={setVisibleModal}
        action= { () => {
          saveData()
        }}
      />
      {/* <TooltipGlodal />
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal> */}
    </>
  );
};

export default NewProduct;
