import React from "react";
import cn from "classnames";
import styles from "./Head.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";
import { db,firebase } from '../../../../Database_fb';
import { useAlert } from 'react-alert'

const Head = ({ selectedOrder, className, onClose, refreshList }) => {
  const alert = useAlert()
  function cancelOrder() {
    if (selectedOrder.order.status == 'cancelled') {
      //alert('order is already cancelled')
      alert.error('order is already cancelled', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }

    if (selectedOrder.order.status == 'refunded') {
     // alert('order refund already issued')
      alert.error('order refund already issued', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }
    if (selectedOrder.order.status == 'completed') {
      //alert('order already redeemed')
      alert.error('order already redeemed', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }
    updateOrderTTable('cancelled', () => {
      if (selectedOrder.user.phonevalue != null && selectedOrder.user.phonevalue != '+') {
        fetch('https://us-central1-morrow-uae.cloudfunctions.net/sendSMS', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: selectedOrder.dish.restaurantName + ' didn’t have food left today, your order have been cancelled. Any questions? Email us: hello@hifz.com',
            tonumber: selectedOrder.user.phonevalue,
          }),
        }, 5000) // throw after max 5 seconds timeout error
          .then((response) => {
            // console.log(response);
          })
          .catch((e) => {
            //console.log(e);
          });
      }
      onClose()
      refreshList()
    })
  }

  function markCollected() {
    if (selectedOrder.order.status == 'cancelled') {
      //alert('order is already cancelled')
      alert.error('order is already cancelled', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }

    if (selectedOrder.order.status == 'refunded') {
      //alert('order refund already issued')
      alert.error('order refund already issued', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }
    if (selectedOrder.order.status == 'completed') {
      //alert('order already redeemed')
      alert.error('order already redeemed', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }
    updateOrderTTable('completed', () => {
      onClose()
      refreshList()
    })
  }

  function refundOrder() {
    if (selectedOrder.order.status == 'cancelled') {
      //alert('order is already cancelled')
      alert.error('order is already cancelled', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }

    if (selectedOrder.order.status == 'refunded') {
      //alert('order refund already issued')
      alert.error('order refund already issued', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }
    if (selectedOrder.order.status == 'completed') {
      //alert('order already redeemed')
      alert.error('order already redeemed', {
        timeout: 3000,
        position: 'middle',
      })
      return
    }
    let isCOD = (selectedOrder.order.paymentMode == 'Cash on Pickup')
    if (isCOD) {
      cancelOrderNow()
      return
    }
  }

  function updateOrderTTable(orderstatus, callback) {
    db.collection("userorders")
      .doc(selectedOrder.user.uid)
      .collection("orders")
      .doc(selectedOrder.order.orderid)
      .update({
        status: orderstatus,
        by: 'store',
        cancelledAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then((ref, error) => {

      });

    db.collection("orders")
      .doc(selectedOrder.order.orderid)
      .update({
        status: orderstatus,
        by: 'store',
        cancelledAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then((ref, error) => {
      });
    if (callback) {
      callback()
      refreshList()
    }
  }

  function cancelOrderNow() {

    let isCOD = (selectedOrder.order.paymentMode == 'Cash on Pickup')
    updateOrderTTable((isCOD ? 'cancelled' : 'refunded'), null)
    if (!isCOD) {

      let newCredits = Number(selectedOrder.user.credits) + selectedOrder.order.totalPrice
      db.collection("users")
        .doc(selectedOrder.user.uid)
        .update({
          credits: newCredits.toString(),
        })
        .then((ref, error) => {
          if (error) {
            // The write failed...
            //Alert.alert(error);
          }
        });
    }
    if (selectedOrder.user.phonevalue != null && selectedOrder.user.phonevalue != '+') {
      fetch('https://us-central1-morrow-uae.cloudfunctions.net/sendSMS', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: selectedOrder.dish.restaurantName + (isCOD ? ' didn’t have food left today, your order have been cancelled. Any questions? Email us: hello@hifz.com' : ' didn’t have food left today, your money have been refunded. Any questions? Email us: hello@hifz.com'),
          tonumber: selectedOrder.user.phonevalue,
        }),
      }, 5000) // throw after max 5 seconds timeout error
        .then((response) => {
          // console.log(response);
        })
        .catch((e) => {
          //console.log(e);
        });
    }

    onClose()
    refreshList()
  }

  return (
    <div className={cn(styles.head, className)}>
      {/* <div className={styles.user}>
        <div className={styles.avatar}>
          <img src={selectedOrder.dish.dishimageURL} alt="Avatar" />
        </div>
        <div className={styles.details}>
          <div className={styles.man}>{selectedOrder.order.dishName}</div>
          <div className={styles.login}>@{selectedOrder.user.firstname + " " + selectedOrder.user.lastname}</div>
        </div>
      </div> */}
      <div className={styles.btns}>
        <button onClick={cancelOrder} className={cn("button-stroke", styles.button)}>
          <span>Cancel</span>
          {/* <Icon name="add" size="24" /> */}
        </button>
        <button onClick={refundOrder} className={cn("button-stroke", styles.button)}>
          <span>Refund</span>
          {/* <Icon name="add" size="24" /> */}
        </button>

        <button onClick={markCollected} className={cn("button", styles.button)}>
          <span>Mark as Collected </span>
          <Icon name="message" size="24" />
        </button>
      </div>
    </div>
  );
};

export default Head;
