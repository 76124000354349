import React, { useState } from "react";
import styles from "./Schedule.module.sass";
import cn from "classnames";
import Item from "./Item";
import Icon from "../Icon";
import Dropdown from "../../components/Dropdown";

import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { db, storage } from '../../Database_fb';
import { act } from "@testing-library/react";

const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const Schedule = ({
  item,
  className,
  startDate,
  setStartDate,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  pickupstartTime,
  pickupendTime,
  setpickupStartTime,
  setpickupEndTime,
  selectedDay,
  setSelectedDay,
  action
}) => {
  const [visibleDate, setVisibleDate] = useState(false);
  const [visibleTime, setVisibleTime] = useState(false);
  const [visibleEndTime, setvisibleEndTime] = useState(false);

  const [pickupvisibleTime, setpickupVisibleTime] = useState(false);
  const [pickupvisibleEndTime, setpickupvisibleEndTime] = useState(false);

  const handleClick = () => {
    setStartDate(null);
    setTimeout(() => setStartDate(Date()), 10);
    setVisibleDate(false);
  };

  function saveData() {
   
    let Close = " - " + format(pickupendTime, "HH:mm")

        let picki = { Open: format(pickupstartTime, "HH:mm"), Close: Close }

        let bClose = " - " + format(endTime, "HH:mm")

        let buyi = { Open: format(startTime, "HH:mm"), Close: bClose }


    db.collection("dishes")
      .doc(item.productKey)
      .update({
            pickup: picki,
            buyTime: buyi,
      })
      .then(() => {
        //alert("Dish data updated successfully")
        action()
      });
  }

  return (
    <div className={cn(styles.schedule, className)}>
      <div className={cn("title-red", styles.title)}>Reschedule product</div>
      <div className={styles.note}>
        Choose a day and time in the future you want your product to be
        published.
      </div>
      <div className={styles.list}>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={selectedDay}
          setValue={(val) => {
            console.log('sort val', val);
            setSelectedDay(val)
          }}
          options={weekdays}
          small
        />
        <br />
        {/* <Item
          className={styles.item}
          category="Date"
          icon="calendar"
          value={startDate && format(startDate, "MMMM dd, yyyy")}
          visible={visibleDate}
          setVisible={setVisibleDate}
        >
          <div className={styles.date}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormatCalendar={"MMMM yyyy"}
              inline
            />
            <div className={styles.foot}>
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={() => handleClick()}
              >
                Clear
              </button>
              <button
                className={cn("button-small", styles.button)}
                onClick={() => setVisibleDate(false)}
              >
                Close
              </button>
            </div>
          </div>
        </Item> */}


        <div className={styles.note}>
          Buy Time
        </div>
        <Item
          className={styles.item}
          category="Open Time"
          icon="clock"
          value={startTime && format(startTime, "HH:mm")}
          visible={visibleTime}
          setVisible={setVisibleTime}
        >
          <div className={styles.time}>
            <div className={styles.top}>
              <div className={styles.subtitle}>
                {startTime && format(startTime, "HH:mm")}
              </div>
              <button
                className={styles.close}
                onClick={() => setVisibleTime(false)}
              >
                <Icon name="close" size="20" />
              </button>
            </div>
            <DatePicker
              selected={startTime}
              onChange={(date) => setStartTime(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption={false}
              dateFormat="HH:mm"
              inline
            />
          </div>
        </Item>

        <Item
          className={styles.item}
          category="Close Time"
          icon="clock"
          value={endTime && format(endTime, "HH:mm")}
          visible={visibleEndTime}
          setVisible={setvisibleEndTime}
        >
          <div className={styles.time}>
            <div className={styles.top}>
              <div className={styles.subtitle}>
                {endTime && format(endTime, "HH:mm")}
              </div>
              <button
                className={styles.close}
                onClick={() => setvisibleEndTime(false)}
              >
                <Icon name="close" size="20" />
              </button>
            </div>
            <DatePicker
              selected={endTime}
              onChange={(date) => setEndTime(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption={false}
              dateFormat="HH:mm"
              inline
            />
          </div>
        </Item>


        <div className={styles.note}>
          Pickup Time
        </div>
        <Item
          className={styles.item}
          category="Open Time"
          icon="clock"
          value={pickupstartTime && format(pickupstartTime, "HH:mm")}
          visible={pickupvisibleTime}
          setVisible={setpickupVisibleTime}
        >
          <div className={styles.time}>
            <div className={styles.top}>
              <div className={styles.subtitle}>
                {pickupstartTime && format(pickupstartTime, "HH:mm")}
              </div>
              <button
                className={styles.close}
                onClick={() => setpickupVisibleTime(false)}
              >
                <Icon name="close" size="20" />
              </button>
            </div>
            <DatePicker
              selected={pickupstartTime}
              onChange={(date) => setpickupStartTime(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption={false}
              dateFormat="HH:mm"
              inline
            />
          </div>
        </Item>

        <Item
          className={styles.item}
          category="Close Time"
          icon="clock"
          value={pickupendTime && format(pickupendTime, "HH:mm")}
          visible={pickupvisibleEndTime}
          setVisible={setpickupvisibleEndTime}
        >
          <div className={styles.time}>
            <div className={styles.top}>
              <div className={styles.subtitle}>
                {pickupendTime && format(pickupendTime, "HH:mm")}
              </div>
              <button
                className={styles.close}
                onClick={() => setpickupvisibleEndTime(false)}
              >
                <Icon name="close" size="20" />
              </button>
            </div>
            <DatePicker
              selected={pickupendTime}
              onChange={(date) => setpickupEndTime(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption={false}
              dateFormat="HH:mm"
              inline
            />
          </div>
        </Item>
      </div>
      <div className={styles.btns}>
        <button onClick={saveData} className={cn("button", styles.button)}>Reschedule</button>
      </div>
    </div>
  );
};

export default Schedule;
