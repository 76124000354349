import React, { useState } from "react";
import styles from "./StoreDetails.module.sass";
import Stores from "./Stores";

const StoreDetails = ({ item , onClick}) => {

  function pressed (){
    onClick(item)
  }
  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          {/* <div className={styles.col}> */}
          <button  onClick={pressed}>
            <Stores className={styles.product} item={item} />
            </button>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default StoreDetails;
