import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../Icon";
import Modal from "../../../Modal";
import Schedule from "../../../Schedule";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Control = ({
  className,
  startDate,
  setStartDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  pickupstartTime,
  setpickupStartTime,
  pickupendTime,
  setpickupEndTime,
  selectedDay,
  setSelectedDay,
  item,
  action
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const history = useHistory();
  const actions = [
    {
      icon: "calendar",
      action: () => setVisibleModal(true),
    },
    {
      icon: "edit",
      action: () => {
        history.push('/products/add',{item: item})
      },
    },
    // {
    //   icon: "trash",
    //   action: () => console.log("delete"),
    // },
  ];
{/* <Link
            className={cn("button-white", styles.button)}
            to="/products/add"
          ></Link> */}
  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            <Icon name={x.icon} size="20" />
          </button>
        ))}
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Schedule
          item={item}
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}

          pickupstartTime={pickupstartTime}
          setpickupStartTime={setpickupStartTime}
          pickupendTime={pickupendTime}
          setpickupEndTime={setpickupEndTime}

          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          action={() => setVisibleModal(false)}
        />

        
      </Modal>
    </>
  );
};

export default Control;
