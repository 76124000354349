import React, { useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Icon from "../../../../Icon";
import ModalPreview from "../../../../ModalPreview";

const gallery = [
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
];

const features = [
  "128 prebuilt screens",
  "SaaS landing page ready",
  "Global styleguide",
  "Dark + light more ready",
];

const Overview = ({ item }) => {
  const [visibleModalPreview, setVisibleModalPreview] = useState(false);

  return (
    <>
      <div className={styles.overview}>
        <div className={cn("h4", styles.title)}>
          {item.dishName}
        </div>
        <div className={styles.info}>
          {item.address}
        </div>
        <div className={styles.line}>
          <div className={styles.author}>
            <div className={styles.avatar}>
              <img src={item.dishimageURL} alt="img" />
            </div>
            {/* by <span>Chelsie Haley</span> */}
          </div>
          <div className={styles.rating}>
            <Icon name="star-fill" size="24" />
            {item.SalePrice} AED
            <span className={styles.counter}>({item.actualPrice} AED) </span>
          </div>
        </div>
        {/* <div className={styles.gallery}>
          {gallery.map(
            (x, index) =>
              index < 1 && (
                <div className={styles.preview} key={index}>
                  <img src={x} alt="Product" />
                </div>
              )
          )}
          <button
            className={cn("button-white", styles.button)}
            onClick={() => setVisibleModalPreview(true)}
          >
            Show all preview
          </button>
        </div> */}
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>Overview</div>
            <div className={styles.content}>
              <p>
                {item.description}
              </p>
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("title-purple", styles.subtitle)}>Other</div>
            <ul className={styles.features}>
              <div className={cn("h6", styles.title)}>
               Stock : {item.Quantity}
              </div>
              <div className={cn("h6", styles.title)}>
              Add Bag : {(item.addBag == true) ? 'Yes' : 'No'}
              </div>
              <div className={cn("h6", styles.title)}>
              Boost : {(item.boost == true) ? 'Yes' : 'No'}
              </div>
              <div className={cn("h6", styles.title)}>
              Cash on Pickup : {(item.cashonPickup == true) ? 'Yes' : 'No'}
              </div>
              <div className={cn("h6", styles.title)}>
              Day : {item.day }
              </div>
              <div className={cn("h6", styles.title)}>
              Category : {item.productCategory}
              </div>
              <div className={cn("h6", styles.title)}>
              Restaurant Name : {item.restaurantName }
              </div>
              <div className={cn("h6", styles.title)}>
              City : {item.city }
              </div>
              <div className={cn("h6", styles.title)}>
              Status : {(item.status == true) ? 'Active' : 'In-Active'}
              </div>
              
              
              {/* {features.map((x, index) => (
                <li key={index}>{x}</li>
              ))} */}
            </ul>
          </div>
        </div>
      </div>
      <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        gallery={gallery}
        title="Fleet - Travel shopping UI design kit"
        figcaption="Elegant product mockup for your next project"
        download
      />
    </>
  );
};

export default Overview;
